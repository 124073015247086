export const PRIORITIZED_MUNICIPALITIES = [
  { id: 'helsinki', translations: { sv: 'Helsingfors', fi: 'Helsinki' } },
  { id: 'espoo', translations: { sv: 'Esbo', fi: 'Espoo' } },
  { id: 'vantaa', translations: { sv: 'Vanda', fi: 'Vantaa' } },
  { id: 'kauniainen', translations: { sv: 'Grankulla', fi: 'Kauniainen' } },
];

export const MUNICIPALITIES = [
  { id: 'akaa', translations: { sv: 'Akaa', fi: 'Akaa' } },
  { id: 'alajärvi', translations: { sv: 'Alajärvi', fi: 'Alajärvi' } },
  { id: 'alavieska', translations: { sv: 'Alavieska', fi: 'Alavieska' } },
  { id: 'alavus', translations: { sv: 'Alavus', fi: 'Alavus' } },
  { id: 'asikkala', translations: { sv: 'Asikkala', fi: 'Asikkala' } },
  { id: 'askola', translations: { sv: 'Askola', fi: 'Askola' } },
  { id: 'aura', translations: { sv: 'Aura', fi: 'Aura' } },
  { id: 'brändö', translations: { sv: 'Brändö', fi: 'Brändö' } },
  { id: 'eckerö', translations: { sv: 'Eckerö', fi: 'Eckerö' } },
  { id: 'enonkoski', translations: { sv: 'Enonkoski', fi: 'Enonkoski' } },
  { id: 'enontekiö', translations: { sv: 'Enontekis', fi: 'Enontekiö' } },
  { id: 'espoo', translations: { sv: 'Esbo', fi: 'Espoo' } },
  { id: 'eura', translations: { sv: 'Eura', fi: 'Eura' } },
  { id: 'eurajoki', translations: { sv: 'Euraåminne', fi: 'Eurajoki' } },
  { id: 'evijärvi', translations: { sv: 'Evijärvi', fi: 'Evijärvi' } },
  { id: 'finström', translations: { sv: 'Finström', fi: 'Finström' } },
  { id: 'forssa', translations: { sv: 'Forssa', fi: 'Forssa' } },
  { id: 'föglö', translations: { sv: 'Föglö', fi: 'Föglö' } },
  { id: 'geta', translations: { sv: 'Geta', fi: 'Geta' } },
  { id: 'haapajärvi', translations: { sv: 'Haapajärvi', fi: 'Haapajärvi' } },
  { id: 'haapavesi', translations: { sv: 'Haapavesi', fi: 'Haapavesi' } },
  { id: 'hailuoto', translations: { sv: 'Karlö', fi: 'Hailuoto' } },
  { id: 'halsua', translations: { sv: 'Halsua', fi: 'Halsua' } },
  { id: 'hamina', translations: { sv: 'Fredrikshamn', fi: 'Hamina' } },
  { id: 'hammarland', translations: { sv: 'Hammarland', fi: 'Hammarland' } },
  { id: 'hankasalmi', translations: { sv: 'Hankasalmi', fi: 'Hankasalmi' } },
  { id: 'hanko', translations: { sv: 'Hangö', fi: 'Hanko' } },
  { id: 'harjavalta', translations: { sv: 'Harjavalta', fi: 'Harjavalta' } },
  { id: 'hartola', translations: { sv: 'Hartola', fi: 'Hartola' } },
  { id: 'hattula', translations: { sv: 'Hattula', fi: 'Hattula' } },
  { id: 'hausjärvi', translations: { sv: 'Hausjärvi', fi: 'Hausjärvi' } },
  { id: 'heinola', translations: { sv: 'Heinola', fi: 'Heinola' } },
  { id: 'heinävesi', translations: { sv: 'Heinävesi', fi: 'Heinävesi' } },
  { id: 'helsinki', translations: { sv: 'Helsingfors', fi: 'Helsinki' } },
  { id: 'hirvensalmi', translations: { sv: 'Hirvensalmi', fi: 'Hirvensalmi' } },
  { id: 'hollola', translations: { sv: 'Hollola', fi: 'Hollola' } },
  { id: 'honkajoki', translations: { sv: 'Honkajoki', fi: 'Honkajoki' } },
  { id: 'huittinen', translations: { sv: 'Huittinen', fi: 'Huittinen' } },
  { id: 'humppila', translations: { sv: 'Humppila', fi: 'Humppila' } },
  { id: 'hyrynsalmi', translations: { sv: 'Hyrynsalmi', fi: 'Hyrynsalmi' } },
  { id: 'hyvinkää', translations: { sv: 'Hyvinge', fi: 'Hyvinkää' } },
  { id: 'hämeenkyrö', translations: { sv: 'Tavastkyro', fi: 'Hämeenkyrö' } },
  { id: 'hämeenlinna', translations: { sv: 'Tavastehus', fi: 'Hämeenlinna' } },
  { id: 'ii', translations: { sv: 'Ii', fi: 'Ii' } },
  { id: 'iisalmi', translations: { sv: 'Idensalmi', fi: 'Iisalmi' } },
  { id: 'iitti', translations: { sv: 'Iitti', fi: 'Iitti' } },
  { id: 'ikaalinen', translations: { sv: 'Ikalis', fi: 'Ikaalinen' } },
  { id: 'ilmajoki', translations: { sv: 'Ilmajoki', fi: 'Ilmajoki' } },
  { id: 'ilomantsi', translations: { sv: 'Ilomants', fi: 'Ilomantsi' } },
  { id: 'imatra', translations: { sv: 'Imatra', fi: 'Imatra' } },
  { id: 'inari', translations: { sv: 'Enare', fi: 'Inari' } },
  { id: 'inkoo', translations: { sv: 'Ingå', fi: 'Inkoo' } },
  { id: 'isojoki', translations: { sv: 'Storå', fi: 'Isojoki' } },
  { id: 'isokyrö', translations: { sv: 'Storkyro', fi: 'Isokyrö' } },
  { id: 'janakkala', translations: { sv: 'Janakkala', fi: 'Janakkala' } },
  { id: 'joensuu', translations: { sv: 'Joensuu', fi: 'Joensuu' } },
  { id: 'jokioinen', translations: { sv: 'Jockis', fi: 'Jokioinen' } },
  { id: 'jomala', translations: { sv: 'Jomala', fi: 'Jomala' } },
  { id: 'joroinen', translations: { sv: 'Jorois', fi: 'Joroinen' } },
  { id: 'joutsa', translations: { sv: 'Joutsa', fi: 'Joutsa' } },
  { id: 'juankoski', translations: { sv: 'Juankoski', fi: 'Juankoski' } },
  { id: 'juuka', translations: { sv: 'Juuka', fi: 'Juuka' } },
  { id: 'juupajoki', translations: { sv: 'Juupajoki', fi: 'Juupajoki' } },
  { id: 'juva', translations: { sv: 'Juva', fi: 'Juva' } },
  { id: 'jyväskylä', translations: { sv: 'Jyväskylä', fi: 'Jyväskylä' } },
  { id: 'jämijärvi', translations: { sv: 'Jämijärvi', fi: 'Jämijärvi' } },
  { id: 'jämsä', translations: { sv: 'Jämsä', fi: 'Jämsä' } },
  { id: 'järvenpää', translations: { sv: 'Träskända', fi: 'Järvenpää' } },
  { id: 'kaarina', translations: { sv: 'S:t Karins', fi: 'Kaarina' } },
  { id: 'kaavi', translations: { sv: 'Kaavi', fi: 'Kaavi' } },
  { id: 'kajaani', translations: { sv: 'Kajana', fi: 'Kajaani' } },
  { id: 'kalajoki', translations: { sv: 'Kalajoki', fi: 'Kalajoki' } },
  { id: 'kangasala', translations: { sv: 'Kangasala', fi: 'Kangasala' } },
  { id: 'kangasniemi', translations: { sv: 'Kangasniemi', fi: 'Kangasniemi' } },
  { id: 'kankaanpää', translations: { sv: 'Kankaanpää', fi: 'Kankaanpää' } },
  { id: 'kannonkoski', translations: { sv: 'Kannonkoski', fi: 'Kannonkoski' } },
  { id: 'kannus', translations: { sv: 'Kannus', fi: 'Kannus' } },
  { id: 'karijoki', translations: { sv: 'Bötom', fi: 'Karijoki' } },
  { id: 'karkkila', translations: { sv: 'Högfors', fi: 'Karkkila' } },
  { id: 'karstula', translations: { sv: 'Karstula', fi: 'Karstula' } },
  { id: 'karvia', translations: { sv: 'Karvia', fi: 'Karvia' } },
  { id: 'kaskinen', translations: { sv: 'Kaskö', fi: 'Kaskinen' } },
  { id: 'kauhajoki', translations: { sv: 'Kauhajoki', fi: 'Kauhajoki' } },
  { id: 'kauhava', translations: { sv: 'Kauhava', fi: 'Kauhava' } },
  { id: 'kauniainen', translations: { sv: 'Grankulla', fi: 'Kauniainen' } },
  { id: 'kaustinen', translations: { sv: 'Kaustby', fi: 'Kaustinen' } },
  { id: 'keitele', translations: { sv: 'Keitele', fi: 'Keitele' } },
  { id: 'kemi', translations: { sv: 'Kemi', fi: 'Kemi' } },
  { id: 'kemijärvi', translations: { sv: 'Kemijärvi', fi: 'Kemijärvi' } },
  { id: 'keminmaa', translations: { sv: 'Keminmaa', fi: 'Keminmaa' } },
  { id: 'kemiönsaari', translations: { sv: 'Kimitoön', fi: 'Kemiönsaari' } },
  { id: 'kempele', translations: { sv: 'Kempele', fi: 'Kempele' } },
  { id: 'kerava', translations: { sv: 'Kervo', fi: 'Kerava' } },
  { id: 'keuruu', translations: { sv: 'Keuruu', fi: 'Keuruu' } },
  { id: 'kihniö', translations: { sv: 'Kihniö', fi: 'Kihniö' } },
  { id: 'kinnula', translations: { sv: 'Kinnula', fi: 'Kinnula' } },
  { id: 'kirkkonummi', translations: { sv: 'Kyrkslätt', fi: 'Kirkkonummi' } },
  { id: 'kitee', translations: { sv: 'Kitee', fi: 'Kitee' } },
  { id: 'kittilä', translations: { sv: 'Kittilä', fi: 'Kittilä' } },
  { id: 'kiuruvesi', translations: { sv: 'Kiuruvesi', fi: 'Kiuruvesi' } },
  { id: 'kivijärvi', translations: { sv: 'Kivijärvi', fi: 'Kivijärvi' } },
  { id: 'kokemäki', translations: { sv: 'Kumo', fi: 'Kokemäki' } },
  { id: 'kokkola', translations: { sv: 'Karleby', fi: 'Kokkola' } },
  { id: 'kolari', translations: { sv: 'Kolari', fi: 'Kolari' } },
  { id: 'konnevesi', translations: { sv: 'Konnevesi', fi: 'Konnevesi' } },
  { id: 'kontiolahti', translations: { sv: 'Kontiolahti', fi: 'Kontiolahti' } },
  { id: 'korsnäs', translations: { sv: 'Korsnäs', fi: 'Korsnäs' } },
  { id: 'koski_tl', translations: { sv: 'Koski Tl', fi: 'Koski Tl' } },
  { id: 'kotka', translations: { sv: 'Kotka', fi: 'Kotka' } },
  { id: 'kouvola', translations: { sv: 'Kouvola', fi: 'Kouvola' } },
  { id: 'kristiinankaupunki', translations: { sv: 'Kristinestad', fi: 'Kristiinankaupunki' } },
  { id: 'kruunupyy', translations: { sv: 'Kronoby', fi: 'Kruunupyy' } },
  { id: 'kuhmo', translations: { sv: 'Kuhmo', fi: 'Kuhmo' } },
  { id: 'kuhmoinen', translations: { sv: 'Kuhmoinen', fi: 'Kuhmoinen' } },
  { id: 'kumlinge', translations: { sv: 'Kumlinge', fi: 'Kumlinge' } },
  { id: 'kuopio', translations: { sv: 'Kuopio', fi: 'Kuopio' } },
  { id: 'kuortane', translations: { sv: 'Kuortane', fi: 'Kuortane' } },
  { id: 'kurikka', translations: { sv: 'Kurikka', fi: 'Kurikka' } },
  { id: 'kustavi', translations: { sv: 'Gustavs', fi: 'Kustavi' } },
  { id: 'kuusamo', translations: { sv: 'Kuusamo', fi: 'Kuusamo' } },
  { id: 'kyyjärvi', translations: { sv: 'Kyyjärvi', fi: 'Kyyjärvi' } },
  { id: 'kärkölä', translations: { sv: 'Kärkölä', fi: 'Kärkölä' } },
  { id: 'kärsämäki', translations: { sv: 'Kärsämäki', fi: 'Kärsämäki' } },
  { id: 'kökar', translations: { sv: 'Kökar', fi: 'Kökar' } },
  { id: 'lahti', translations: { sv: 'Lahtis', fi: 'Lahti' } },
  { id: 'laihia', translations: { sv: 'Laihela', fi: 'Laihia' } },
  { id: 'laitila', translations: { sv: 'Laitila', fi: 'Laitila' } },
  { id: 'lapinjärvi', translations: { sv: 'Lappträsk', fi: 'Lapinjärvi' } },
  { id: 'lapinlahti', translations: { sv: 'Lapinlahti', fi: 'Lapinlahti' } },
  { id: 'lappajärvi', translations: { sv: 'Lappajärvi', fi: 'Lappajärvi' } },
  { id: 'lappeenranta', translations: { sv: 'Villmanstrand', fi: 'Lappeenranta' } },
  { id: 'lapua', translations: { sv: 'Lappo', fi: 'Lapua' } },
  { id: 'laukaa', translations: { sv: 'Laukaa', fi: 'Laukaa' } },
  { id: 'lemi', translations: { sv: 'Lemi', fi: 'Lemi' } },
  { id: 'lemland', translations: { sv: 'Lemland', fi: 'Lemland' } },
  { id: 'lempäälä', translations: { sv: 'Lempäälä', fi: 'Lempäälä' } },
  { id: 'leppävirta', translations: { sv: 'Leppävirta', fi: 'Leppävirta' } },
  { id: 'lestijärvi', translations: { sv: 'Lestijärvi', fi: 'Lestijärvi' } },
  { id: 'lieksa', translations: { sv: 'Lieksa', fi: 'Lieksa' } },
  { id: 'lieto', translations: { sv: 'Lundo', fi: 'Lieto' } },
  { id: 'liminka', translations: { sv: 'Limingo', fi: 'Liminka' } },
  { id: 'liperi', translations: { sv: 'Liperi', fi: 'Liperi' } },
  { id: 'lohja', translations: { sv: 'Lojo', fi: 'Lohja' } },
  { id: 'loimaa', translations: { sv: 'Loimaa', fi: 'Loimaa' } },
  { id: 'loppi', translations: { sv: 'Loppi', fi: 'Loppi' } },
  { id: 'loviisa', translations: { sv: 'Lovisa', fi: 'Loviisa' } },
  { id: 'luhanka', translations: { sv: 'Luhanka', fi: 'Luhanka' } },
  { id: 'lumijoki', translations: { sv: 'Lumijoki', fi: 'Lumijoki' } },
  { id: 'lumparland', translations: { sv: 'Lumparland', fi: 'Lumparland' } },
  { id: 'luoto', translations: { sv: 'Larsmo', fi: 'Luoto' } },
  { id: 'luumäki', translations: { sv: 'Luumäki', fi: 'Luumäki' } },
  { id: 'luvia', translations: { sv: 'Luvia', fi: 'Luvia' } },
  { id: 'maalahti', translations: { sv: 'Malax', fi: 'Maalahti' } },
  { id: 'maarianhamina', translations: { sv: 'Mariehamn', fi: 'Maarianhamina' } },
  { id: 'marttila', translations: { sv: 'Marttila', fi: 'Marttila' } },
  { id: 'masku', translations: { sv: 'Masku', fi: 'Masku' } },
  { id: 'merijärvi', translations: { sv: 'Merijärvi', fi: 'Merijärvi' } },
  { id: 'merikarvia', translations: { sv: 'Sastmola', fi: 'Merikarvia' } },
  { id: 'miehikkälä', translations: { sv: 'Miehikkälä', fi: 'Miehikkälä' } },
  { id: 'mikkeli', translations: { sv: 'S:t Michel', fi: 'Mikkeli' } },
  { id: 'muhos', translations: { sv: 'Muhos', fi: 'Muhos' } },
  { id: 'multia', translations: { sv: 'Multia', fi: 'Multia' } },
  { id: 'muonio', translations: { sv: 'Muonio', fi: 'Muonio' } },
  { id: 'mustasaari', translations: { sv: 'Korsholm', fi: 'Mustasaari' } },
  { id: 'muurame', translations: { sv: 'Muurame', fi: 'Muurame' } },
  { id: 'mynämäki', translations: { sv: 'Mynämäki', fi: 'Mynämäki' } },
  { id: 'myrskylä', translations: { sv: 'Mörskom', fi: 'Myrskylä' } },
  { id: 'mäntsälä', translations: { sv: 'Mäntsälä', fi: 'Mäntsälä' } },
  { id: 'mänttä-vilppula', translations: { sv: 'Mänttä-Vilppula', fi: 'Mänttä-Vilppula' } },
  { id: 'mäntyharju', translations: { sv: 'Mäntyharju', fi: 'Mäntyharju' } },
  { id: 'naantali', translations: { sv: 'Nådendal', fi: 'Naantali' } },
  { id: 'nakkila', translations: { sv: 'Nakkila', fi: 'Nakkila' } },
  { id: 'nivala', translations: { sv: 'Nivala', fi: 'Nivala' } },
  { id: 'nokia', translations: { sv: 'Nokia', fi: 'Nokia' } },
  { id: 'nousiainen', translations: { sv: 'Nousis', fi: 'Nousiainen' } },
  { id: 'nurmes', translations: { sv: 'Nurmes', fi: 'Nurmes' } },
  { id: 'nurmijärvi', translations: { sv: 'Nurmijärvi', fi: 'Nurmijärvi' } },
  { id: 'närpiö', translations: { sv: 'Närpes', fi: 'Närpiö' } },
  { id: 'orimattila', translations: { sv: 'Orimattila', fi: 'Orimattila' } },
  { id: 'oripää', translations: { sv: 'Oripää', fi: 'Oripää' } },
  { id: 'orivesi', translations: { sv: 'Orivesi', fi: 'Orivesi' } },
  { id: 'oulainen', translations: { sv: 'Oulainen', fi: 'Oulainen' } },
  { id: 'oulu', translations: { sv: 'Uleåborg', fi: 'Oulu' } },
  { id: 'outokumpu', translations: { sv: 'Outokumpu', fi: 'Outokumpu' } },
  { id: 'padasjoki', translations: { sv: 'Padasjoki', fi: 'Padasjoki' } },
  { id: 'paimio', translations: { sv: 'Pemar', fi: 'Paimio' } },
  { id: 'paltamo', translations: { sv: 'Paltamo', fi: 'Paltamo' } },
  { id: 'parainen', translations: { sv: 'Pargas', fi: 'Parainen' } },
  { id: 'parikkala', translations: { sv: 'Parikkala', fi: 'Parikkala' } },
  { id: 'parkano', translations: { sv: 'Parkano', fi: 'Parkano' } },
  { id: 'pedersören_kunta', translations: { sv: 'Pedersöre', fi: 'Pedersören kunta' } },
  { id: 'pelkosenniemi', translations: { sv: 'Pelkosenniemi', fi: 'Pelkosenniemi' } },
  { id: 'pello', translations: { sv: 'Pello', fi: 'Pello' } },
  { id: 'perho', translations: { sv: 'Perho', fi: 'Perho' } },
  { id: 'pertunmaa', translations: { sv: 'Pertunmaa', fi: 'Pertunmaa' } },
  { id: 'petäjävesi', translations: { sv: 'Petäjävesi', fi: 'Petäjävesi' } },
  { id: 'pieksämäki', translations: { sv: 'Pieksämäki', fi: 'Pieksämäki' } },
  { id: 'pielavesi', translations: { sv: 'Pielavesi', fi: 'Pielavesi' } },
  { id: 'pietarsaari', translations: { sv: 'Jakobstad', fi: 'Pietarsaari' } },
  { id: 'pihtipudas', translations: { sv: 'Pihtipudas', fi: 'Pihtipudas' } },
  { id: 'pirkkala', translations: { sv: 'Birkala', fi: 'Pirkkala' } },
  { id: 'polvijärvi', translations: { sv: 'Polvijärvi', fi: 'Polvijärvi' } },
  { id: 'pomarkku', translations: { sv: 'Påmark', fi: 'Pomarkku' } },
  { id: 'pori', translations: { sv: 'Björneborg', fi: 'Pori' } },
  { id: 'pornainen', translations: { sv: 'Borgnäs', fi: 'Pornainen' } },
  { id: 'porvoo', translations: { sv: 'Borgå', fi: 'Porvoo' } },
  { id: 'posio', translations: { sv: 'Posio', fi: 'Posio' } },
  { id: 'pudasjärvi', translations: { sv: 'Pudasjärvi', fi: 'Pudasjärvi' } },
  { id: 'pukkila', translations: { sv: 'Pukkila', fi: 'Pukkila' } },
  { id: 'punkalaidun', translations: { sv: 'Punkalaidun', fi: 'Punkalaidun' } },
  { id: 'puolanka', translations: { sv: 'Puolanka', fi: 'Puolanka' } },
  { id: 'puumala', translations: { sv: 'Puumala', fi: 'Puumala' } },
  { id: 'pyhtää', translations: { sv: 'Pyttis', fi: 'Pyhtää' } },
  { id: 'pyhäjoki', translations: { sv: 'Pyhäjoki', fi: 'Pyhäjoki' } },
  { id: 'pyhäjärvi', translations: { sv: 'Pyhäjärvi', fi: 'Pyhäjärvi' } },
  { id: 'pyhäntä', translations: { sv: 'Pyhäntä', fi: 'Pyhäntä' } },
  { id: 'pyhäranta', translations: { sv: 'Pyhäranta', fi: 'Pyhäranta' } },
  { id: 'pälkäne', translations: { sv: 'Pälkäne', fi: 'Pälkäne' } },
  { id: 'pöytyä', translations: { sv: 'Pöytyä', fi: 'Pöytyä' } },
  { id: 'raahe', translations: { sv: 'Brahestad', fi: 'Raahe' } },
  { id: 'raasepori', translations: { sv: 'Raseborg', fi: 'Raasepori' } },
  { id: 'raisio', translations: { sv: 'Reso', fi: 'Raisio' } },
  { id: 'rantasalmi', translations: { sv: 'Rantasalmi', fi: 'Rantasalmi' } },
  { id: 'ranua', translations: { sv: 'Ranua', fi: 'Ranua' } },
  { id: 'rauma', translations: { sv: 'Raumo', fi: 'Rauma' } },
  { id: 'rautalampi', translations: { sv: 'Rautalampi', fi: 'Rautalampi' } },
  { id: 'rautavaara', translations: { sv: 'Rautavaara', fi: 'Rautavaara' } },
  { id: 'rautjärvi', translations: { sv: 'Rautjärvi', fi: 'Rautjärvi' } },
  { id: 'reisjärvi', translations: { sv: 'Reisjärvi', fi: 'Reisjärvi' } },
  { id: 'riihimäki', translations: { sv: 'Riihimäki', fi: 'Riihimäki' } },
  { id: 'ristijärvi', translations: { sv: 'Ristijärvi', fi: 'Ristijärvi' } },
  { id: 'rovaniemi', translations: { sv: 'Rovaniemi', fi: 'Rovaniemi' } },
  { id: 'ruokolahti', translations: { sv: 'Ruokolahti', fi: 'Ruokolahti' } },
  { id: 'ruovesi', translations: { sv: 'Ruovesi', fi: 'Ruovesi' } },
  { id: 'rusko', translations: { sv: 'Rusko', fi: 'Rusko' } },
  { id: 'rääkkylä', translations: { sv: 'Rääkkylä', fi: 'Rääkkylä' } },
  { id: 'saarijärvi', translations: { sv: 'Saarijärvi', fi: 'Saarijärvi' } },
  { id: 'salla', translations: { sv: 'Salla', fi: 'Salla' } },
  { id: 'salo', translations: { sv: 'Salo', fi: 'Salo' } },
  { id: 'saltvik', translations: { sv: 'Saltvik', fi: 'Saltvik' } },
  { id: 'sastamala', translations: { sv: 'Sastamala', fi: 'Sastamala' } },
  { id: 'sauvo', translations: { sv: 'Sagu', fi: 'Sauvo' } },
  { id: 'savitaipale', translations: { sv: 'Savitaipale', fi: 'Savitaipale' } },
  { id: 'savonlinna', translations: { sv: 'Nyslott', fi: 'Savonlinna' } },
  { id: 'savukoski', translations: { sv: 'Savukoski', fi: 'Savukoski' } },
  { id: 'seinäjoki', translations: { sv: 'Seinäjoki', fi: 'Seinäjoki' } },
  { id: 'sievi', translations: { sv: 'Sievi', fi: 'Sievi' } },
  { id: 'siikainen', translations: { sv: 'Siikainen', fi: 'Siikainen' } },
  { id: 'siikajoki', translations: { sv: 'Siikajoki', fi: 'Siikajoki' } },
  { id: 'siikalatva', translations: { sv: 'Siikalatva', fi: 'Siikalatva' } },
  { id: 'siilinjärvi', translations: { sv: 'Siilinjärvi', fi: 'Siilinjärvi' } },
  { id: 'simo', translations: { sv: 'Simo', fi: 'Simo' } },
  { id: 'sipoo', translations: { sv: 'Sibbo', fi: 'Sipoo' } },
  { id: 'siuntio', translations: { sv: 'Sjundeå', fi: 'Siuntio' } },
  { id: 'sodankylä', translations: { sv: 'Sodankylä', fi: 'Sodankylä' } },
  { id: 'soini', translations: { sv: 'Soini', fi: 'Soini' } },
  { id: 'somero', translations: { sv: 'Somero', fi: 'Somero' } },
  { id: 'sonkajärvi', translations: { sv: 'Sonkajärvi', fi: 'Sonkajärvi' } },
  { id: 'sotkamo', translations: { sv: 'Sotkamo', fi: 'Sotkamo' } },
  { id: 'sottunga', translations: { sv: 'Sottunga', fi: 'Sottunga' } },
  { id: 'sulkava', translations: { sv: 'Sulkava', fi: 'Sulkava' } },
  { id: 'sund', translations: { sv: 'Sund', fi: 'Sund' } },
  { id: 'suomussalmi', translations: { sv: 'Suomussalmi', fi: 'Suomussalmi' } },
  { id: 'suonenjoki', translations: { sv: 'Suonenjoki', fi: 'Suonenjoki' } },
  { id: 'sysmä', translations: { sv: 'Sysmä', fi: 'Sysmä' } },
  { id: 'säkylä', translations: { sv: 'Säkylä', fi: 'Säkylä' } },
  { id: 'taipalsaari', translations: { sv: 'Taipalsaari', fi: 'Taipalsaari' } },
  { id: 'taivalkoski', translations: { sv: 'Taivalkoski', fi: 'Taivalkoski' } },
  { id: 'taivassalo', translations: { sv: 'Tövsala', fi: 'Taivassalo' } },
  { id: 'tammela', translations: { sv: 'Tammela', fi: 'Tammela' } },
  { id: 'tampere', translations: { sv: 'Tammerfors', fi: 'Tampere' } },
  { id: 'tervo', translations: { sv: 'Tervo', fi: 'Tervo' } },
  { id: 'tervola', translations: { sv: 'Tervola', fi: 'Tervola' } },
  { id: 'teuva', translations: { sv: 'Östermark', fi: 'Teuva' } },
  { id: 'tohmajärvi', translations: { sv: 'Tohmajärvi', fi: 'Tohmajärvi' } },
  { id: 'toholampi', translations: { sv: 'Toholampi', fi: 'Toholampi' } },
  { id: 'toivakka', translations: { sv: 'Toivakka', fi: 'Toivakka' } },
  { id: 'tornio', translations: { sv: 'Torneå', fi: 'Tornio' } },
  { id: 'turku', translations: { sv: 'Åbo', fi: 'Turku' } },
  { id: 'tuusniemi', translations: { sv: 'Tuusniemi', fi: 'Tuusniemi' } },
  { id: 'tuusula', translations: { sv: 'Tusby', fi: 'Tuusula' } },
  { id: 'tyrnävä', translations: { sv: 'Tyrnävä', fi: 'Tyrnävä' } },
  { id: 'ulvila', translations: { sv: 'Ulvsby', fi: 'Ulvila' } },
  { id: 'urjala', translations: { sv: 'Urjala', fi: 'Urjala' } },
  { id: 'utajärvi', translations: { sv: 'Utajärvi', fi: 'Utajärvi' } },
  { id: 'utsjoki', translations: { sv: 'Utsjoki', fi: 'Utsjoki' } },
  { id: 'uurainen', translations: { sv: 'Uurainen', fi: 'Uurainen' } },
  { id: 'uusikaarlepyy', translations: { sv: 'Nykarleby', fi: 'Uusikaarlepyy' } },
  { id: 'uusikaupunki', translations: { sv: 'Nystad', fi: 'Uusikaupunki' } },
  { id: 'vaala', translations: { sv: 'Vaala', fi: 'Vaala' } },
  { id: 'vaasa', translations: { sv: 'Vasa', fi: 'Vaasa' } },
  { id: 'valkeakoski', translations: { sv: 'Valkeakoski', fi: 'Valkeakoski' } },
  { id: 'valtimo', translations: { sv: 'Valtimo', fi: 'Valtimo' } },
  { id: 'vantaa', translations: { sv: 'Vanda', fi: 'Vantaa' } },
  { id: 'varkaus', translations: { sv: 'Varkaus', fi: 'Varkaus' } },
  { id: 'vehmaa', translations: { sv: 'Vehmaa', fi: 'Vehmaa' } },
  { id: 'vesanto', translations: { sv: 'Vesanto', fi: 'Vesanto' } },
  { id: 'vesilahti', translations: { sv: 'Vesilahti', fi: 'Vesilahti' } },
  { id: 'veteli', translations: { sv: 'Vetil', fi: 'Veteli' } },
  { id: 'vieremä', translations: { sv: 'Vieremä', fi: 'Vieremä' } },
  { id: 'vihti', translations: { sv: 'Vichtis', fi: 'Vihti' } },
  { id: 'viitasaari', translations: { sv: 'Viitasaari', fi: 'Viitasaari' } },
  { id: 'vimpeli', translations: { sv: 'Vimpeli', fi: 'Vimpeli' } },
  { id: 'virolahti', translations: { sv: 'Virolahti', fi: 'Virolahti' } },
  { id: 'virrat', translations: { sv: 'Virdois', fi: 'Virrat' } },
  { id: 'vårdö', translations: { sv: 'Vårdö', fi: 'Vårdö' } },
  { id: 'vöyri', translations: { sv: 'Vörå', fi: 'Vöyri' } },
  { id: 'ylitornio', translations: { sv: 'Övertorneå', fi: 'Ylitornio' } },
  { id: 'ylivieska', translations: { sv: 'Ylivieska', fi: 'Ylivieska' } },
  { id: 'ylöjärvi', translations: { sv: 'Ylöjärvi', fi: 'Ylöjärvi' } },
  { id: 'ypäjä', translations: { sv: 'Ypäjä', fi: 'Ypäjä' } },
  { id: 'ähtäri', translations: { sv: 'Etseri', fi: 'Ähtäri' } },
  { id: 'äänekoski', translations: { sv: 'Äänekoski', fi: 'Äänekoski' } },
];

export const SWITCH_APPLICATION_LIMIT = 5;
